import http from '@/utils/http';

//  分页
export function listPage(formdata: any): any {
  return http.get(`/portal-resources/1/portalInformation/portalInformation`, formdata);
}

//  新增
export function dataAdd(formdata: any): any {
  return http.post(`/portal-resources/1/portalInformation`, formdata);
}

//  修改
export function dataEidt(formdata: any): any {
  return http.put(`/portal-resources/1/portalInformation`, formdata);
}

//  详情
export function dataDetail(id: string): any {
  return http.get(`/portal-resources/1/portalInformation/${id}`);
}

//  删除
export function dataDelete(formdata: any): any {
  return http.delete(`/portal-resources/1/portalInformation`, formdata);
}