



















































































import Vue from 'vue';
import moment from 'moment';
import * as Api from '@/api/newsManage';
import MyEditor from '@/components/global/myEditor/index.vue';
import { dateStringToTimestamp, timestampFormatDate } from '@/utils/index';
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    width: '300px',
  },
  {
    title: '封面图片',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    scopedSlots: { customRender: 'image' },
  },
  {
    title: '发布时间',
    dataIndex: 'openDate',
    scopedSlots: { customRender: 'openDate' },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'dictList',
  components: { MyEditor },
  data() {
    return {
      editFlag: false,
      tableLoading: false,
      searchForm: {},
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      formState: {
        dictName: '',
        dictCode: '',
      },
      dataSource,
      columns,
      formVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      form: {
        title: '',
        information: '', //HTML的资源详情-用于展示
        informationJson: '', //JSON格式的资源详情-用于编辑
        image: '',
        openDate: '',
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'change',
          },
        ],
        description: [
          {
            required: true,
            message: '请输入简介',
            trigger: 'change',
          },
        ],
        information: [
          {
            required: true,
            message: '请输入正文内容',
            trigger: 'change',
          },
        ],
        image: [
          {
            required: true,
            message: '请选择封面图片',
            trigger: 'change',
          },
        ],
        openDate: [
          {
            required: true,
            message: '请选择发布时间',
            trigger: 'change',
          },
        ],
      },
      deleteVisible: false,
      deleteId: 0,
      optionType: '',
      previewVisible: false,
      previewImage: '',
    };
  },
  created() {
    this.handleSubmit();
  },
  methods: {
    moment,
    updateApplicationLogoUrl(url: string) {
      this.$set(this.form, 'image', url);
    },
    handleEditorData(prodDetailsJson: string) {
      return prodDetailsJson ? JSON.parse(prodDetailsJson) : [];
    },
    // 查询按钮
    handleSubmit() {
      this.pagination.current = 1;
      this.getDataList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      this.searchForm = {};
      this.pagination.current = 1;
      this.getDataList();
    },
    getDataList() {
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.searchForm,
      };
      this.tableLoading = true;
      Api.listPage(param)
        .then((res) => {
          const data = res;
          if (data.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    // 新增字典目录
    newForm(record, optionType) {
      this.optionType = optionType;
      this.formVisible = true;
      this.$nextTick(() => {
        setTimeout(()=>{
          this.editFlag = true;
        },1000)
        this.$refs.ruleForm.resetFields();
        this.$nextTick(() => {
          if (optionType === 'edit') {
            Api.dataDetail(record.sequenceNbr).then((res) => {
              this.form = res.result;
              if (this.form.openDate) {
                this.form.openDate = timestampFormatDate(
                  this.form.openDate
                ).slice(0, 10);
              }
            });
          } else {
            this.form = {
              title: '',
              information: '',
              informationJson: '',
              image: '',
              openDate: '',
            };
            this.handleEditorData("");
          }
        });
      });
    },
    // 字典目录表单提交
    onSubmit() {
      let information=(this.$refs.editor as HTMLFormElement).latestContentHtml
      let informationJson=(this.$refs.editor as HTMLFormElement).latestContentJson
      if(information&&information!="<p><br/></p>"){
        this.form.information = information;
        this.form.informationJson = informationJson;
      }
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          let formData = JSON.parse(JSON.stringify(this.form));
          formData.openDate = dateStringToTimestamp(
            formData.openDate + ' 00:00:00'
          );
          if (this.optionType === 'add') {
            Api.dataAdd(formData)
              .then((res) => {
                if (res.status===200) {
                  this.$message.success('新增成功！');
                  this.loading = true;
                  this.handleSubmit();
                  this.cancel()
                }
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            Api.dataEidt(formData)
              .then((res) => {
                if (res.status===200) {
                  this.$message.success('修改成功！');
                  this.loading = true;
                  this.handleSubmit();
                  this.cancel()
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.formVisible = false;
      this.editFlag=false
    },
    // 删除字典目录
    deleteDict(record) {
      this.deleteVisible = true;
      this.deleteId = record.sequenceNbr;
    },
    deleteConfirm() {
      Api.dataDelete([this.deleteId])
        .then((res) => {
          if (res) {
            this.loading = true;
            this.deleteVisible = false;
            this.handleSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    imageView(url) {
      this.previewImage = url;
      this.previewVisible = true;
    },
  },
});
